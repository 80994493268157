import React,{useEffect,useRef,useState} from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useGetAllCentersQuery, useGetResultMutation } from '../Services/modules/result';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PersonelResult({exam_type}) {
    const [center, setCenter] = useState('');
    const [seatNumber, setSeatNumber] = useState('');
    const [filteredData, setFilteredData] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  
  const[resultDisplay,setResultDisplay]=useState([])
  const[studentName,setStudentName]=useState('')
  const[studentSchool,setStudentSchool]=useState('')
  const[studentStandard,setStudentStandard]=useState('')
  const[studentSeatNumber,setStudentSeatNumber]=useState('')
  const[studentExamCenter,setStudentExamCenter]=useState('')
  const[studentExamSubject,setStudentExamSubject]=useState('')
  const[studentMarkOne,setStudentMarkOne]=useState('')
  const[studentMarkTwo,setStudentMarkTwo]=useState('')
  const[studentTotalMarks,setStudentTotalMarks]=useState('')

  const { 
    data: centers= [],  
    isLoading:loading=true,
    isSuccess:getCentersSuccess=false
   } = useGetAllCentersQuery();
   const [GetResult, 
    { data, isSuccess, isLoading, isError, error }]
     = useGetResultMutation();
     useEffect(() => {
       
        validateForm();
      }, [center,seatNumber]);
       useEffect(() => {    
         
        if (getCentersSuccess) {
           
           console.log("centers.data",centers.data)
              setFilteredData(centers.data);
            
        }
       
    }, [centers]);
    useEffect(() => {   
         
      if (isSuccess) { 
        console.log("result data",data)        
       // console.log("data",data.result[0].student_name)   
        if(data.error) 
        {
          toast.error(data.message) 
         // alert(data.result[0].student_name)
        
        }
        if(!data.error)
        {
          toast.success(data.message)  
          setStudentName(data.result[0].student_name)
          setStudentSchool(data.school_name)
          setStudentStandard(data.result[0].standard)
          setStudentSeatNumber(data.result[0].seat_number)
          setStudentSeatNumber(data.result[0].seat_number)
          setStudentMarkOne(data.result[0].mark_one)
          setStudentMarkTwo(data.result[0].mark_two)
          setStudentTotalMarks(data.result[0].total_marks)    
          setResultDisplay(data.result)      
        }
                  
      }
      else {
      console.log("In error")
         
      }
    }, [isLoading]);
    const validateForm = () => {
         
        
      if (center !== '' && seatNumber!=='') {
        setIsFormValid(true);
      } 
      else 
      {           
        setIsFormValid(false);
      }       
    }; 
    const handleFormSubmit = (exam_type) => {
        GetResult({
          center_id:center,
          seat_number:seatNumber,
          exam_name:exam_type
        })
        };
    
  return (
    <>
     <div className='row'>
            <div className='col-md-4'>
            <div className='row' style={{marginTop:'50px'}}>
       <h4 style={{textAlign:'center'}}> वैयक्तिक अंतिम निकाल खालील प्रमाणे पहा</h4>
        </div>
          
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label style={{marginTop:'20px'}}>केंद्र निवडा:</Form.Label>
        <Form.Control as="select" value={center} onChange={e => setCenter(e.target.value)}>
          <option value="">Choose...</option>
          {filteredData.map(item => (
                        <option value={item.center_id}>{item.center_name}</option>
                      ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label style={{marginTop:'20px'}}>बैठक क्रमांक:</Form.Label>
        {/* <Form.Control type="text" value={seatNumber} onChange={e => setSeatNumber(e.target.value)} /> */}
        <Form.Control 
    type="text" 
    value={seatNumber} 
    onChange={e => {
        const inputValue = e.target.value;
        if (!inputValue.includes(' ')) {
            setSeatNumber(inputValue);
        }
    }} 
/>
      </Form.Group>
      {/* <Button 
      style={{marginTop:'20px'}} 
      variant="primary"
    //  disabled={true} 
      disabled={!isFormValid}
      type="submit">
        Submit
      </Button> */}
      <Button
  style={{ marginTop: '20px' }}
  variant="primary"
  disabled={!isFormValid || isLoading}
  type="submit"
  onClick={()=>handleFormSubmit(exam_type)}
>
  {isLoading ? (
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  ) : (
    'Submit'
  )}
</Button>
   
            </div>
            <div className='col-md-6' >
           {resultDisplay.length>0? 
           <table class="table"style={{marginTop:'40px',marginLeft:"40px"}} >
    <thead>

      <tr>
      {
      studentStandard==1 || studentStandard==2 || studentStandard==3 || studentStandard==6 ?
        <th colSpan={2} style={{textAlign:'center'}}>
          गुणवत्ता शोध परीक्षा निकाल</th>:
           <th colSpan={2} style={{textAlign:'center'}}>
           शताब्दी शिष्यवृत्ती परीक्षा निकाल</th>
    }
       
      </tr>
    </thead>
    <tbody>
   

      <tr>
        <td style={{textAlign:'left'}}>नाव</td>
        <td style={{textAlign:'left'}}>{studentName}</td>
       
      </tr>
      <tr>
        <td style={{textAlign:'left'}}>विद्यार्थ्याची शाळा</td>
        <td style={{textAlign:'left'}}>{studentSchool}</td>
      
      </tr>
      <tr>
        <td style={{textAlign:'left'}}>इयत्ता</td>
        <td style={{textAlign:'left'}}>{studentStandard}</td>
       
      </tr>
      <tr>
        <td style={{textAlign:'left'}}>बैठक क्रमांक</td>
        <td style={{textAlign:'left'}}>{studentSeatNumber}</td>
       
      </tr>
      {/* <tr>
        <td style={{textAlign:'left'}}>परीक्षा केंद्र</td>
        <td style={{textAlign:'left'}}>Dooley</td>
       
      </tr> */}
     
    
    {exam_type=='tse'? 
    <tr>
        <td style={{textAlign:'left'}}>
        {studentStandard==1 || studentStandard==2?  "भाषा, गणित व बुद्धिमत्ता" :"भाषा व गणित"}
          
          </td>
        <td style={{textAlign:'left'}}>{studentMarkOne}</td>
       
      </tr>
      
      :
      
      ''}
      
      {
        studentStandard==3 || studentStandard==6 ?
        <tr>
        <td style={{textAlign:'left'}}>इंग्रजी व बुद्धिमत्ता</td>
        <td style={{textAlign:'left'}}>{studentMarkTwo}</td>
       
      </tr>:""
      }
        {exam_type=='shatabdi'? 
        <tr>
        <td style={{textAlign:'left'}}>भाषा व गणित</td>
        <td style={{textAlign:'left'}}>{studentMarkOne}</td>
       
      </tr>
      
      :
      ''}
       {exam_type=='shatabdi'? 
        <tr>
        <td style={{textAlign:'left'}}>इंग्रजी व बुद्धिमत्ता</td>
        <td style={{textAlign:'left'}}>{studentMarkTwo}</td>
       
      </tr>
      
      :
      ''}
        <tr>
        <td style={{textAlign:'left'}}>एकूण</td>
        <td style={{textAlign:'left'}}>{studentTotalMarks}</td>
       
      </tr>
     
    </tbody>
  </table>:""}
            </div>
        </div>
    </>
  )
}

export default PersonelResult