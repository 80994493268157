import React,{useEffect,useRef,useState} from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useCheckCenterMeritListShatabdiMutation, useCheckCenterMeritListTseMutation, useGetAllCentersQuery, useGetResultMutation } from '../Services/modules/result';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import checkCenterMeritListShatabdi from '../Services/modules/result/checkCenterMeritListShatabdi';
import { Config } from '../Config';

function GeneralMeritList({exam_type}) {
  const navigate = useNavigate();
  
  return (
    <>
     <div className='row'>
           
            <div className='col-md-6' >
          
        
          <table class="table"style={{marginTop:'40px',marginLeft:"40px"}} >
    <thead>

      <tr>
     
     
        <th colSpan={2} style={{textAlign:'center'}}>
          गुणवत्ता शोध परीक्षा - सर्व साधारण गुणवत्ता यादी खालील प्रमाणे
          </th>
    
       
      </tr>
    </thead>
    <tbody>
   

      <tr>       
        <td style={{textAlign:'left'}}>
        <a href={`${Config.file_url}/GeneralMeritList/tse/1_TSE_Rural_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>

        सर्वसाधारण गुणवत्ता यादी १ली ग्रामीण </a>
        </td>        
      </tr>
      <tr>       
       <td style={{textAlign:'left'}}>
       <a href={`${Config.file_url}/GeneralMeritList/tse/1_TSE_Urban_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>

       सर्वसाधारण गुणवत्ता यादी १ली शहरी </a>
       </td>       
     </tr>
      <tr>       
       <td style={{textAlign:'left'}}>
       <a href={`${Config.file_url}/GeneralMeritList/tse/1_TSE_State_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>

       राज्यस्तरीय प्रथम तीन क्रमांक १ली </a>
       </td>       
     </tr>
    

     <tr>       
        <td style={{textAlign:'left'}}>
        <a href={`${Config.file_url}/GeneralMeritList/tse/2_TSE_Rural_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>
        सर्वसाधारण गुणवत्ता यादी २री ग्रामीण </a>
        </td>        
      </tr>
      <tr>       
       <td style={{textAlign:'left'}}>
       <a href={`${Config.file_url}/GeneralMeritList/tse/2_TS_ Urban_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>
       सर्वसाधारण गुणवत्ता यादी २री शहरी </a>
       </td>       
     </tr>
      {/* <tr>       
       <td style={{textAlign:'left'}}>
       <a href={`${Config.file_url}/GeneralMeritList/tse/2_TSE_State_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>

       राज्यस्तरीय प्रथम तीन क्रमांक २री </a>
       </td>       
     </tr> */}
     <tr>       
        <td style={{textAlign:'left'}}>
        <a href={`${Config.file_url}/GeneralMeritList/tse/3_TSE_Rural_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>

        सर्वसाधारण गुणवत्ता यादी ३री ग्रामीण </a>
        </td>        
      </tr>
      <tr>       
       <td style={{textAlign:'left'}}>
       <a href={`${Config.file_url}/GeneralMeritList/tse/3_TSE_Urban_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>

       सर्वसाधारण गुणवत्ता यादी ३री शहरी </a>
      
       </td>       
     </tr>
      <tr>       
       <td style={{textAlign:'left'}}>
       <a href={`${Config.file_url}/GeneralMeritList/tse/3_TSE_State_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>

       राज्यस्तरीय प्रथम तीन क्रमांक ३री </a>
       </td>       
     </tr>
     <tr>       
        <td style={{textAlign:'left'}}>
        <a href={`${Config.file_url}/GeneralMeritList/tse/6_TSE_Rural_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>

        सर्वसाधारण गुणवत्ता यादी ६वी ग्रामीण </a>
        </td>        
      </tr>
      <tr>       
       <td style={{textAlign:'left'}}>
       <a href={`${Config.file_url}/GeneralMeritList/tse/6_TSE Urban_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>

       सर्वसाधारण गुणवत्ता यादी ६वी शहरी </a>
       </td>       
     </tr>
      <tr>       
       <td style={{textAlign:'left'}}>
      
       <a href={`${Config.file_url}/GeneralMeritList/tse/6_TSE_State_Prize_Holders_List.pdf`} target="_blank" style={{ color: 'blue' }}>
       राज्यस्तरीय प्रथम तीन क्रमांक ६वी 
    </a>
     
       </td>       
     </tr>
    
     
     
      
     
     
    </tbody>
  </table>
            </div>
        </div>
    </>
  )
}

export default GeneralMeritList